* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
}

button, input {
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.main_page {
    margin-bottom: 80px;
}

.swiper_container {
    margin-top: 28px;
    position: relative;
}

.swiper_container .swiper-slide {
    width: 220px;
    height: 220px;
    position: relative;
}

.swiper_container .swiper-slide img {
    width: 220px;
    height: 220px;
    object-fit: cover;
}

.defaultSwiper {
    width: 100%;
    height: 100%;
    padding: 0 18px;
}

@media (min-width: 0) and (max-width: 344px) {
    .defaultSwiper {
        padding: 0 5px;
    }
}